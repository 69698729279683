import React, { useRef, useState, useEffect } from "react";

const Rates = (props) => {
  const [open, setOpen] = useState(false);

  const renderRate5 = () => {
    return (
      <div>
        <div className="rates__header">
          <div className="rates__header__title">December 25 - January 3</div>
          <div className="rates__header__icon">
            <div className="material-icons">expand_more</div>
          </div>
        </div>
        <div className="rates__info-container">
          <div className="rates__values-container">
            <div className="rates__info-title">Dates</div>
            <div className="rates__info">
              <div className="rates__value">December 25 - January 3</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Rates</div>
            <div className="rates__info">
              <div className="rates__value">$34,650 per week</div>
              <div className="rates__value">$4,950 each additional day</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Minimum Nights</div>
            <div className="rates__info">
              <div className="rates__value">10</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRate4 = () => {
    return (
      <div>
        <div className="rates__header">
          <div className="rates__header__title">Novermber 1 - December 24</div>
          <div className="rates__header__icon">
            <div className="material-icons">expand_more</div>
          </div>
        </div>
        <div className="rates__info-container">
          <div className="rates__values-container">
            <div className="rates__info-title">Dates</div>
            <div className="rates__info">
              <div className="rates__value">Novermber 1 - December 24</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Rates</div>
            <div className="rates__info">
              <div className="rates__value">$24,500 per week</div>
              <div className="rates__value">$3,500 each additional day</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Minimum Nights</div>
            <div className="rates__info">
              <div className="rates__value">7</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRate3 = () => {
    return (
      <div>
        <div className="rates__header">
          <div className="rates__header__title">August 1 - October 31</div>
          <div className="rates__header__icon">
            <div className="material-icons">expand_more</div>
          </div>
        </div>
        <div className="rates__info-container">
          <div className="rates__values-container">
            <div className="rates__info-title">Dates</div>
            <div className="rates__info">
              <div className="rates__value">August 1 - October 31</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Rates</div>
            <div className="rates__info">
              <div className="rates__value">$21,000 per week</div>
              <div className="rates__value">$3,000 each additional day</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Minimum Nights</div>
            <div className="rates__info">
              <div className="rates__value">5</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRate2 = () => {
    return (
      <div>
        <div className="rates__header">
          <div className="rates__header__title">May 1 - July 31</div>
          <div className="rates__header__icon">
            <div className="material-icons">expand_more</div>
          </div>
        </div>
        <div className="rates__info-container">
          <div className="rates__values-container">
            <div className="rates__info-title">Dates</div>
            <div className="rates__info">
              <div className="rates__value">May 1 - July 31</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Rates</div>
            <div className="rates__info">
              <div className="rates__value">$24,500 per week</div>
              <div className="rates__value">$3,500 each additional day</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Minimum Nights</div>
            <div className="rates__info">
              <div className="rates__value">5</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRate1 = () => {
    return (
      <div>
        <div className="rates__header">
          <div className="rates__header__title">January 1 - April 30</div>
          <div className="rates__header__icon">
            <div className="material-icons">expand_more</div>
          </div>
        </div>
        <div className="rates__info-container">
          <div className="rates__values-container">
            <div className="rates__info-title">Dates</div>
            <div className="rates__info">
              <div className="rates__value">January 1 - April 30</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Rates</div>
            <div className="rates__info">
              <div className="rates__value">$29,750 per week</div>
              <div className="rates__value">$4,250 each additional day</div>
            </div>
          </div>
          <div className="rates__values-container">
            <div className="rates__info-title">Minimum Nights</div>
            <div className="rates__info">
              <div className="rates__value">7</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderRate1()}
      {renderRate2()}
      {renderRate3()}
      {renderRate4()}
      {renderRate5()}
    </div>
  );
};

export default Rates;
