import React, { useRef, useState, useEffect } from "react";

const OverlayVideo = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.querySelector("body").style.overflow = 'hidden'
    } else {
      document.querySelector("body").removeAttribute('style');
    }
  });

  const renderOverlay = () => {
    if (open) {
      return <div className="overlay__background animated fast-1 fadeIn">
        <div className="overlay animated delay-1 fadeInUp--small">
          <div className="overlay__close" onClick={() => setOpen(false)}>
            <div className="budicon-solid solid-budicon-cross-ui"></div>
          </div>
          <div className="container">

            <div className="overlay__content">
              <div className="overlay__content__header">
                <div className="overlay__content__header__title">
                  Video Property Tour
                </div>

                <div className="overlay__content__header__subtitle">
                  Step inside luxury with a video tour of Sol y Sombra.
                </div>
              </div>
              <div className="overlay__content__body">
                <div className="overlay__content__body__media">

                  <iframe className="responsive__media" src="https://www.youtube.com/embed/D3OebsojuVs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>

                <div className="overlay__content__body__description">
                  STEPS AWAY FROM Little Trunk Bay, this classic and timeless British colonial beachfront villa has five suites, each furnished with custom-made teak furniture.
              </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    }
  }

  return (
    <div>
      {renderOverlay()}
      <div className="hero__header__video_content__container animated fadeInUp">
        <div className="hero__header__video_content__thumbnail" onClick={() => setOpen(true)}>
          <div className="btn btn--play">
            <div className="budicon-solid solid-budicon-play"></div>
          </div>
        </div>
        <div className="hero__header__video_content__text__container">
          <div className="hero__header__video_content__title">video tour</div>
          <div className="hero__header__video_content__text">Step inside luxury with a video tour of Sol y Sombra.</div>
        </div>
      </div>
    </div>
  )

}

export default OverlayVideo;