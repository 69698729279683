import React, { useRef, useState, useEffect } from "react";
import Calendar from "react-calendar";
import houseIcon from "house_icon@3x.png";
import bathIcon from "bath_icon@3x.png";
import bedIcon from "bed_icon@3x.png";
import loungeIcon from "lounge_icon@3x.png";
import kitchenIcon from "kitchen_icon@3x.png";

const DetailsPanel = (props) => {
  const detailsPanelOffset = 50;
  var detailsPanel = useRef();
  var detailsPanelPosition = useRef();
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    detailsPanel.current = document.querySelector("#detailsPanel");
    detailsPanelPosition.current =
      window.pageYOffset + (detailsPanel.current.getBoundingClientRect().top - detailsPanelOffset);

    window.addEventListener("scroll", panelLock);
  }, []);

  const panelLock = () => {
    if (window.pageYOffset >= detailsPanelPosition.current) {
      detailsPanel.current.classList.add("panel--fixed");
    } else {
      detailsPanel.current.classList.remove("panel--fixed");
    }
  };

  return (
    <div className="panel" id="detailsPanel">
      <div className="panel__content">
        <div className="panel__about">
          <div className="panel__logo"></div>
          <div className="panel__about__text-container">
            <div className="panel__about__title">SOL Y SOMBRA</div>
            <div className="panel__about__subtitle">Sun and Shade</div>
          </div>
          <div className="panel__about__tag">Private Villa</div>
          <div className="panel__features">
            <div
              className="panel__features__container panel__features__container--border-right"
              style={{ width: "45%" }}
            >
              <div className="panel__features__item">
                <div className="panel__features__item__icon">
                  <i className="solid solid-budicon-mansion" />
                </div>
                <div className="panel__features__item__name">
                  <span style={{ fontWeight: 700, color: "#002A4C", marginRight: 5 }}>8,000</span>
                  sqft
                </div>
              </div>
              <div className="panel__features__item">
                <div className="panel__features__item__icon" data-count="6">
                  <i className="solid solid-budicon-bed" />
                </div>
                <div className="panel__features__item__name">Bedrooms</div>
              </div>
            </div>
            <div
              className="panel__features__container panel__features__container--padding-left"
              style={{ width: "55%" }}
            >
              <div className="panel__features__item">
                <div className="panel__features__item__icon">
                  <i className="solid solid-budicon-beach" />
                </div>
                <div className="panel__features__item__name">Private Beach</div>
              </div>
              <div className="panel__features__item">
                <div className="panel__features__item__icon">
                  <i className="solid solid-budicon-sun-set" />
                </div>
                <div className="panel__features__item__name">Panoramic Views</div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel__notice__container" style={{ marginBottom: 20 }}>
          <a href="/enquiries/new">
            <div className="btn btn--tiny">Contact Us</div>
          </a>
          <div className="panel__notice__title">Get in touch</div>
          <div className="panel__notice__message">
            Have a question?
            <br />
            Send us a message and we will get back to you.
          </div>
        </div>
        <div id="datepicker"></div>
        <div className="panel__request">
          <Calendar onChange={onChange} value={value} />
          <div className="panel__request__title">Dates</div>
          <div className="panel__request__details">
            <div className="panel__request__details__value">checkin</div>
            <div className="panel__request__details__value">checkout</div>
          </div>
          <a href="/enquiries/new">
            <div className="panel__tab__btn">Request booking</div>
          </a>
        </div>
      </div>
    </div>
  );
};
export default DetailsPanel;
